import React, { useEffect } from "react";
import NavBar from "./NavBar";

const JoinUs = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.Tally) {
        window.Tally.loadEmbeds();
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-linear-black-blue text-offWhite">
      <NavBar showButtons={false} />
      <div className="container mx-auto px-4 md:px-6 lg:px-8 mt-20">
        <h1 className="text-xl font-bold mb-8 font-sans">Join Us</h1>
        <p className="mb-8 text-md font-sans">
          At Mantis, we’re leading the way in AI and data visualization. We’re
          seeking talented individuals to join us in revolutionizing cognitive
          cartography.
        </p>
        <p className="mb-8 text-md font-sans">
          If you're passionate about turning complex data into actionable
          insights, we want you on our team. Apply now to help shape the future
          of data exploration.
        </p>
        <div className="flex justify-center">
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-16 max-w-2xl w-full">
            <iframe
              data-tally-src="https://tally.so/embed/mKooEz?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
              loading="lazy"
              width="100%"
              height="768"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Tell me about your interest in Mantis"
              className="w-full h-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
