import React, { useEffect } from "react";
import NavBar from "./NavBar";
import SignUpForm from "./signupform";

const Request = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.Tally) {
        window.Tally.loadEmbeds();
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-linear-black-grey text-offWhite">
      <NavBar showButtons={false} />
      <div className="container mx-auto px-4 md:px-6 lg:px-8 mt-20">
        <h1 className="text-xl font-semibold mb-8 font-sans">
          Experience Mantis in Action
        </h1>
        <p className="mb-8 text-md font-sans">
          Discover how Mantis can revolutionize your data visualization and
          decision-making processes. Request a demo today and see our innovative
          cognitive cartography platform in action. Fill out the form below, and
          one of our representatives will be in touch shortly to schedule your
          personalized demonstration.
        </p>
        <div className="flex justify-center">
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-16 max-w-2xl w-full">
            <SignUpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
