import React from "react";
import Lottie from "react-lottie";
import checkAnimation from "../assets/green-check.json";
import NavBar from "./NavBar";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Thanks = () => {
  return (
    <div className="flex flex-col min-h-screen bg-linear-black-blue text-offWhite font-sans">
      <NavBar showButtons={false} />
      <div className="container mx-auto px-4 md:px-6 lg:px-8 mt-20">
        <div className="flex items-center mb-8">
          <div className="w-14 h-14 md:w-24 md:h-24 lg:w-28 lg:h-28">
            <Lottie options={defaultOptions} />
          </div>
          <h1 className="text-xl font-bold ml-4 sm:text-xl md:text-3xl lg:text-xxl">
            Submitted!
          </h1>
        </div>
        <h2 className="text-lg font-semibold mb-6 sm:text-md md:text-lg">
          Thank You for Your Interest in Joining the Mantis Teams
        </h2>
        <p className="text-sm mb-6 sm:text-sm md:text-md">
          We appreciate your application and the time you've taken to express
          your interest in Mantis. Our team is currently reviewing your
          submission.
        </p>
        <p className="text-sm mb-6 sm:text-sm md:text-md">
          If your qualifications align with our needs, you will receive an email
          with details on the next steps in our hiring process.{" "}
        </p>
        <p className="text-sm mb-6 sm:text-sm md:text-md">
          We value your enthusiasm and look forward to potentially welcoming you
          to our innovative team. In the meantime, feel free to explore more
          about our mission and projects on our website.{" "}
        </p>
      </div>
    </div>
  );
};

export default Thanks;
