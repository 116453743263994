import React from "react";
import Logo from "../assets/images/mantis-logo-white.svg";

const Footer = () => {
  return (
    <footer className="bg-offBlack text-offWhite py-4">
      <div className="container mx-auto flex items-center justify-between px-4">
        <div className="flex items-center space-x-2">
          <img src={Logo} alt="Mantis AI" className="h-8 md:h-10" />
          <span className="text-base md:text-lg font-medium">Mantis</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
