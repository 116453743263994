import React from "react";
import Lottie from "react-lottie";
import checkAnimation from "../assets/green-check.json";
import NavBar from "./NavBar";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ThankYou = () => {
  return (
    <div className="flex flex-col min-h-screen bg-linear-black-grey text-offWhite font-sans">
      <NavBar showButtons={false} />
      <div className="container mx-auto px-4 md:px-6 lg:px-8 mt-20">
        <div className="flex items-center mb-8">
          <div className="w-14 h-14 md:w-24 md:h-24 lg:w-28 lg:h-28">
            <Lottie options={defaultOptions} />
          </div>
          <h1 className="text-xl font-bold ml-4 sm:text-xl md:text-3xl lg:text-xxl">
            Submitted!
          </h1>
        </div>
        <h2 className="text-lg font-semibold mb-6 sm:text-md md:text-lg">
          Thank You for Your Interest in Mantis
        </h2>
        <p className="text-sm mb-6 sm:text-sm md:text-md">
          Thank you for requesting a demo! Our team will get in touch with you
          shortly to schedule your personalized demonstration. One of our
          representatives will reach out to you within 24-48 hours.
        </p>
        <p className="text-sm mb-6 sm:text-sm md:text-md">
          In the meantime, feel free to learn more about our mission and explore
          our projects on our website.
        </p>
      </div>
    </div>
  );
};

export default ThankYou;

/* add later when we have a help email
<p className="text-sm mb-6 sm:text-sm md:text-md">
          If you have any questions, please feel free to contact us at{" "}
          <a
            href="mailto:support@mantis.com"
            className="text-blue-400 underline"
          >
            support@mantis.com
          </a>
          .
        </p>
*/