import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/mantis-logo-white.svg';
import '../assets/styles/customStyles.css';
import { Bars3Icon, XMarkIcon, HomeIcon, InformationCircleIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const NavBar = ({ showButtons = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleNavClick = (path, sectionId) => {
        if (location.pathname === '/') {
            scrollToSection(sectionId);
        } else {
            navigate(path);
            setTimeout(() => scrollToSection(sectionId), 100);
        }
        setIsOpen(false);
    };

    const handleHomeClick = (e) => {
        e.preventDefault();
        if (location.pathname === '/') {
            scrollToTop();
        } else {
            navigate('/');
        }
        setIsOpen(false);
    };

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="sticky top-0 bg-offBlack text-offWhite font-sans z-50">
            <div className="container mx-auto flex items-center justify-between px-4 py-4">
                <div className="flex items-center space-x-2">
                    <Link to="/" onClick={handleHomeClick} className="flex items-center space-x-2">
                        <img src={Logo} alt="Mantis" className="h-8 md:h-10" />
                        <span className="text-base md:text-lg font-medium">Mantis</span>
                    </Link>
                </div>
                <div className="hidden md:flex items-center space-x-6 ml-4">
                    <Link to="/" onClick={handleHomeClick} className="nav-item text-xs md:text-sm font-medium transition duration-300">Home</Link>
                    <button onClick={() => handleNavClick('/', 'about-section')} className="nav-item text-xs md:text-sm font-medium transition duration-300">About</button>
                    <button onClick={() => handleNavClick('/', 'team-section')} className="nav-item text-xs md:text-sm font-medium transition duration-300">Team</button>

                </div>
                {showButtons && (
                    <div className="hidden md:flex items-center space-x-4 ml-auto">
                        <Link to="/inquire">
                            <button className="bg-offWhite text-offBlack py-1 px-2 md:py-2 md:px-4 rounded-lg border border-offWhite hover:bg-offBlack hover:text-offWhite transition duration-300 text-xs md:text-base">Request a Demo</button>
                        </Link>
                        <Link to="/join">
                            <button className="border border-offWhite text-offWhite py-1 px-2 md:py-2 md:px-4 rounded-lg hover:bg-offWhite hover:text-offBlack transition duration-300 text-xs md:text-base">Get Involved</button>
                        </Link>
                    </div>
                )}
                <div className="md:hidden ml-auto">
                    <button onClick={toggleMenu}>
                        {isOpen ? <XMarkIcon className="h-6 w-6 text-offWhite" /> : <Bars3Icon className="h-6 w-6 text-offWhite" />}
                    </button>
                </div>
            </div>
            {isOpen && (
    <div className="md:hidden fixed inset-0 bg-offBlack text-offWhite flex flex-col items-start justify-start pt-16 px-4 z-50">
        <div className="absolute top-4 left-4 flex items-center space-x-2">
            <img src={Logo} alt="Mantis" className="h-8" />
            <span className="text-base font-medium">Mantis</span>
        </div>
        <button className="absolute top-4 right-4" onClick={toggleMenu}>
            <XMarkIcon className="h-6 w-6 text-offWhite" />
        </button>
        <Link to="/" onClick={handleHomeClick} className="nav-item text-2xl font-medium transition duration-300 border-b-2 border-transparent hover:border-offWhite flex items-center space-x-2 py-4 w-full">
            <HomeIcon className="h-6 w-6" />
            <span>Home</span>
        </Link>
        <button onClick={() => handleNavClick('/', 'about-section')} className="nav-item text-2xl font-medium transition duration-300 border-b-2 border-transparent hover:border-offWhite flex items-center space-x-2 py-4 w-full">
            <InformationCircleIcon className="h-6 w-6" />
            <span>About</span>
        </button>
        <button onClick={() => handleNavClick('/', 'team-section')} className="nav-item text-2xl font-medium transition duration-300 border-b-2 border-transparent hover:border-offWhite flex items-center space-x-2 py-4 w-full">
            <UserGroupIcon className="h-6 w-6" />
            <span>Team</span>
        </button>
        {showButtons && (
            <div className="w-full mt-8 space-y-4">
                <Link to="/inquire" className="block w-full">
                    <button onClick={() => setIsOpen(false)} className="bg-offWhite text-offBlack py-3 px-6 rounded-lg border border-offWhite hover:bg-offBlack hover:text-offWhite transition duration-300 w-full">Request a Demo</button>
                </Link>
                <Link to="/join" className="block w-full">
                    <button onClick={() => setIsOpen(false)} className="border border-offWhite text-offWhite py-3 px-6 rounded-lg hover:bg-offWhite hover:text-offBlack transition duration-300 w-full">Get Involved</button>
                </Link>
            </div>
        )}
    </div>
)}
        </div>
    );
};

export default NavBar;
